export const team = `name,pfp,title
Ryan Sproule,ryan.png,Research Engineer
Caleb Tebbe,caleb-1.png,Research Engineer
Bart Stephens,bart.png,Founder
Brad Stephens,brad.png,Founder
Aleks Larsen,aleks.png,General Partner
Spencer Bogart,spencer-1.png,General Partner
Kinjal Shah,kinjal.png,Partner
Zalia Aliriza,zalia.png,CFO
Sterling Campbell,sterling.png,Associate
Adam Patel,adam-1.png,Senior Analyst
Yuan Han Li,yuan.png,Senior Analyst
Shishi Feng,shishi-1.png,Analyst
H. Joshua Rivera,joshua.png,General Counsel
Sonia Aggarwal,sonia-1.png,Associate General Counsel
Tony Schmidt,tony.png,COO
Alan Curtis,alan.png,Head of Platform
Chunda,chunda.png,Research Scholar
JoAnne Lauer,joanne.png,Director of Investor Relations
Jason Di Piazza,jason.png,Head of Capital Formation
Kyle Gluskin,kyle.png,Investor Relations Specialist
Allie Spencer,allie.png,Office Manager
John Filbert,john.png,Controller
Jane Eberts,jane.png,Executive Assistant
Brittany hart,brit.png,Executive Assistant
`;
