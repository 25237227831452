export const ascii = `                                                                                                                                                                                                          
                                                                                                                                                                                                        
                                                                       &@@/                                                                                                                             
                                                                       .(/                                                                                                                              
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                               &@@@@@@/                                                                                                                 
                                                                               &@@@@@@/                                                                                                                 
                                                                               (@@@@@@,                                                                                                                 
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                                                                                                                      ./,                               
                                                                                      *@@@@@@@&#,                                                                     @@@,                              
                                                                                      @@@@@@@@@@@%                                                                                                      
                                                                                     %@@@@@@@@@@@.                                                                                                      
                                                                                     @@@@@@@@@@@/                                                                                                       
                                                                                        .(@@@@@%                                                         *@@@@@&                                        
                                                                                                                                                        .@@@@@@@#                                       
                                                                                              /(.                                                        *@@@@@@#                                       
                                                                                            /@@@@@@@*                                                                                                   
                                                                                           &@@@@@@@@@@@@%.                               .*********                                                     
                                                                                         .@@@@@@@@@@@@@@@@@*                            ,@@@@@@@@@@@.                                                   
                                                                                        /@@@@@@@@@@@@@@@@@.                             ,@@@@@@@@@@@.                                                   
                                                                                       ,@@@@@@@@@@@@@@@@%                               ,@@@@@@@@@@@.                                                   
                                                                                          *%@@@@@@@@@@@/              #@@@@@@@&(/,      .@@@@@@@@@@@.                                                   
                                                                                              ./@@@@@@.              *@@@@@@@@@@@@@@@/                                                                  
                                                                                                 &@@@@@&,            %@@@@@@@@@@@@@@@                                                                   
                                                                                               %@@@@@@@@@@@@@/.     *@@@@@@@@@@@@@@@(                                                                   
                                                                         (@@&.               %@@@@@@@@@@@@@@@@@@@@&@@@@@@@@@@@@@@@@@                                                                    
                                                   ,@&,                @@@@@@@@/          .@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*                                                                    
                                                  &@@@@@@&,         (@@@@@@@@@@@@@.     .@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@.  *#&@@@@&                                                                     
                                                (@@@@@@@@@@@@,   ,@@@@@@@@@@@@@@@@@@/ ,@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@                                                                                 
                               .@@@@@@#        &@@@@@@@@@@@@*    %@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ click-me @@@@@@@@@@@%                                                                                 
                               @@@@@@@%         /@@@@@@@@@%        /@@@@@@@@@@@@@@&..&@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@,                                                                                 
                               (&@@@@&              /@@@@,           .&@@@@@@@@@*     ,@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#                                                                                  
                ,@@@(                                                   /@@@@%          #@@@@@@@@@@@@@@@@@@@@@@@@@@@@,                                                                                  
                                                                                          @@@@@@@@@@@@@@@@@@@@@@@@@@@                                                                                   
                                                                                           *@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&                                                                       
                                                                                             #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@,                                                                      
                                                                                            *&@@@@@@@@#*.        ,@@@@@@@@@@@@@@@*                                                                      
                                                                                      ,&@@@@@@@@@(               .@@@@@@@@@@@@@@@*                                                                      
                                                                                  %@@@@@@@@@@@@@@@,               @@@@@@@@@@@@@@@/                                                                      
                                                                                  (@@@@@@@@@@@@@@@@,              @@@@@@@@@@@@@@@/                                                                      
                                                                                   *@@@@@@@@@@@@@@@@/                                     ,/,                                                           
                                                                                    *@@@@@@@@@@@@@@@@                             ,@@@@@@@@@@%                                                          
                                                                                     *@@@@@@@@@@%*                                *@@@@@@@@@@@*                                                         
                                                                                      ,@@@&,                                       %@@@@@@@@@@&                                                         
                                                                                                                                   ,@@@@@@@@@@@.                                                        
                                                                                  #@@@/                                             ,%%(*.                                                              
                                                                              *@@@@@@@@@.                                                            *&@@@@(                                            
                                                                            /@@@@@@@@@@@@@.                                                         /@@@@@@@&                                           
                                                                             ,@@@@@@@@@@@@%                                                          .@@@@@#.                                           
                                                                               /@@@@@@@%.                                                                                                               
                                                                                 #@@*                                                                                 #@@@,                             
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                            /@@@@@*                                                                                                                     
                                                                           &@@@@@@@%                                                                                                                    
                                                                            *@@@@@.                                                                                                                     
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                             .,.                                                                                                                        
                                                                            *@@@.                                                                                                                       
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                                                                                                                                                         
                                                                                                                                                                                                        
     @@@@@@@@@@@@@@@@@    @@@@@             @@@@@@@@@@@@@@@      .@@@@@@@@@@@@@@#    @@@@@     @@@@@@    @@@@@@@@@@@@@@@    @@@@@*       /@@@@%       @@@@@@@@/       @@@@@*  &@@@@@@       @@@@@       
     @@@@@       @@@@@&   @@@@@           @@@@@@@    .@@@@@@@   @@@@@@@    &@@@@@@   @@@@@  @@@@@@/    @@@@@@@.   *@@@@@@&  @@@@@*       /@@@@%      @@@@@%@@@@&      @@@@@*  &@@@@@@@@/    @@@@@       
     @@@@@@@@@@@@@@@@     @@@@@          @@@@@@         @@@@@( @@@@@%                @@@@@@@@@@&      /@@@@@                @@@@@@@@@@@@@@@@@@%     @@@@@  /@@@@@     @@@@@*  &@@@@*@@@@@@  @@@@@       
     @@@@@@@@@@@@@@@@@@   @@@@@          &@@@@@         @@@@@/ @@@@@@                @@@@@/@@@@@@     /@@@@@                @@@@@@@@@@@@@@@@@@%    @@@@@    ,@@@@@    @@@@@*  &@@@@.  @@@@@@@@@@@       
     @@@@@       #@@@@@*  @@@@@@@@@@@@@@  @@@@@@@(   %@@@@@@@   @@@@@@@*  .@@@@@@@   @@@@@   @@@@@@/   @@@@@@@%   &@@@@@@%  @@@@@*       /@@@@%   @@@@@@@@@@@@@@@@@   @@@@@*  &@@@@.    %@@@@@@@@       
     @@@@@@@@@@@@@@@@@.   @@@@@@@@@@@@@@    @@@@@@@@@@@@@@%       @@@@@@@@@@@@@@,    @@@@@     &@@@@@@   %@@@@@@@@@@@@@&    @@@@@*       /@@@@% .@@@@@         @@@@@  @@@@@*  &@@@@.       @@@@@@       
                                                                                                                                                                                                        
                                                                                                                                                                                                        
        ,@@@@@@@@@@*          &@@@@@@(        @@@@@@@@@@@@@@/     @@@@@ *@@@@@@@@@@@@@@@@@     @@@@@@@        @@@@@*                                                                                    
     %@@@@@@@@@@@@@@@@@      @@@@@@@@@@       @@@@@@@@@@@@@@@@@   @@@@@ *@@@@@@@@@@@@@@@@@    @@@@@@@@@       @@@@@*                                                                                    
    @@@@@@        @@@@@%    @@@@@ /@@@@@      @@@@@       #@@@@@  @@@@@        @@@@@        ,@@@@& @@@@@.     @@@@@*                                                                                    
    @@@@@,                 @@@@@   .@@@@@     @@@@@@@@@@@@@@@@@   @@@@@        @@@@@       (@@@@#   @@@@@/    @@@@@*                                                                                    
    #@@@@@.       @@@@@#  @@@@@@@@@@@@@@@@    @@@@@@@@@@@@@@.     @@@@@        @@@@@      &@@@@@@@@@@@@@@@@   @@@@@*                                                                                    
      @@@@@@@@@@@@@@@@/  @@@@@########@@@@@.  @@@@@               @@@@@        @@@@@     @@@@@########&@@@@@  @@@@@@@@@@@@@@@                                                                           
         *@@@@@@@@/    .@@@@@          @@@@@* @@@@@               @@@@@        @@@@@    @@@@@          (@@@@@ @@@@@@@@@@@@@@@                                                                           
                                                                                                                                                                                                        
                                                                                                                                                                                                        
                                                                                                                              `;
