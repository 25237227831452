import { BrowserRouter, Route, Routes } from "react-router-dom";
import { EntryPage } from "./pages/EntryPage";
import { TeamPage } from "./pages/TeamPage";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<EntryPage />} path="/" />
          <Route element={<TeamPage />} path="/team" />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
