import { useEffect, useState } from "react";

import { ascii } from "./ascii";

interface HeaderProps {
  onClick: () => void;
}
export const BCapAsciiHeader = (props: HeaderProps) => {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(index + 7);
    }, 1);
    return () => clearInterval(intervalId);
  }, [index]);

  return (
    <div className="section">
      <div
        onClick={() => props.onClick()}
        style={{
          fontFamily: "monospace",
          fontSize: "min(.75vh, .78vw)",
          fontWeight: 900,
          overflow: "hidden",
          backgroundColor: "transparent",
          color: "#12ff1a",
          display: "block",
          float: "left",
          textAlign: "center",
          width: "100%",
          whiteSpace: "pre-wrap",
          margin: "auto",
        }}
      >
        {ascii.substring(0, index)}
      </div>
    </div>
  );
};
