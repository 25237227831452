import { TeamMember } from "../../components/TeamMember";
import { team as ts } from "./team";
const team = parseCSV(ts, true)
export const TeamPage = () => {
  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: "100vw",
        alignContent: "center",
      }}
    >
      {team.map((tm: TeamMemberProps, i: number) => {
        return <TeamMember key={i} {...tm} />;
      })}
    </div>
  );
};

export interface TeamMemberProps {
  name: string;
  pfp: string;
  title: string;
  twitter: string | null;
  github: string | null;
}

function parseCSV(csv: string, header: boolean) {
  let lines = csv.split("\n");
  let namesLines = header ? lines.slice(1, lines.length) : lines;
  return namesLines.map((line: string) => {
    let fields = line.split(",");
    return {
      name: fields[0],
      pfp: fields[1],
      title: fields[2],
      twitter: fields[3],
      github: fields[4],
    } as TeamMemberProps;
  });
}
