import { Figure } from "react-bootstrap";
import { TeamMemberProps } from "../../pages/TeamPage";
import { useGlitch } from "react-powerglitch";
import { useState } from "react";

export const TeamMember = (props: TeamMemberProps) => {
  const glitch = useGlitch({ playMode: "hover" });
  const [showDetails, setShowDetails] = useState(false);
  return (
    <span ref={glitch.ref} style={{ padding: "15px", zIndex: 2, position: "relative" }}>
      <Figure style={{display: "block"}}  onClick={() => setShowDetails(!showDetails)}>
        <Figure.Image src={`pfps/${props.pfp}`} fluid width="800vw" />
        {showDetails ? (
          <div
           className="overlay"
          >
            <Figure.Caption>{props.name +  "_"}</Figure.Caption>
            <Figure.Caption>{props.title+  "_"}</Figure.Caption>
            <Figure.Caption>{props.twitter}</Figure.Caption>
          </div>
        ) : (
          ""
        )}
      </Figure>
    </span>
  );
};
