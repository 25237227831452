import { Environment } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

import { StarModel } from "../Star";

interface AnimProps {
  onClick: () => void;
}
export const AnimLogo = (props: AnimProps) => {
  return (
    <div
      className="section"
      onClick={props.onClick}
      style={{
        backgroundColor: "transparent",
        height: "100vh",
        width: "100%",
      }}
    >
      <Canvas camera={{ position: [0, 0, 15] }}>
        <Environment files="adamsbridge.hdr" />
        <spotLight position={[8, 8, 12]} angle={0.15} penumbra={1} />
        <pointLight position={[-8, -8, 12]} />
        <StarModel />
      </Canvas>
    </div>
  );
};
