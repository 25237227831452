import { useState } from "react";
import { AnimLogo } from "../../components/AnimLogo";
import { Background } from "../../components/Background3js";
import { BCapAsciiHeader } from "../../components/BCapAsciiHeader";
import { TeamPage } from "../TeamPage";

export const EntryPage = () => {
  const [animLogo, flipLogo] = useState<boolean>(false);

  const flip = () => {
    flipLogo(!animLogo);
  };
  return (
    <>
      <Background />
      <div style={{ zIndex: 1, position: "absolute" }}>
        {!animLogo ? (
          <BCapAsciiHeader onClick={flip} />
        ) : (
          <AnimLogo onClick={flip} />
        )}
        <TeamPage />
      </div>
    </>
  );
};
