//@ts-nocheck
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { MeshStandardMaterial } from "three";

export function StarModel(props: any) {
  const { nodes } = useGLTF("star.gltf");
  const ref = useRef();
  useFrame(
    (state, delta) =>
      //@ts-ignore
      (ref.current.rotation.x = ref.current.rotation.y += delta / 2)
  );
  return (
    <group ref={ref} {...props} dispose={null} scale={0.05}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body3.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body17.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body18.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body19.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body20.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body21.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body22.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body23.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body24.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body25.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body26.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body27.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body28.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body29.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body30.geometry}
        material={diamondMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Body31.geometry}
        material={diamondMaterial}
      />
    </group>
  );
}

useGLTF.preload("star.gltf");
const diamondMaterial = new MeshStandardMaterial({
  color: "#265c26",
  // emissive: "",
  roughness: 0.1,
});
